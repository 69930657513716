import { Box, useToast, Spinner, Stack } from "@chakra-ui/react";
import { CustomButton } from "../components/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../components/APIs";

import { employement_statement_array, loanPlan, yesNo } from "../util/data";
import { CustomInput, CustumSelect } from "../components/Input";
import Cookies from "js-cookie";

export default function OnboardingForm({userDetails}) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [notFullyRepaid, setNotFullyRepaid] = useState(false);
  const [repayingDirect, setRepaying] = useState(true);
  const uid = Cookies.get("uid");
  const formik = useFormik({
    initialValues: {
      employment_statement: "",
      not_fully_repaid: "",
      repaying: "",
      plan: "",
      finish: "",
      date_of_appointment: "",
      national_insurance_number: "",
      uid: uid,
    },
    validationSchema: Yup.object({
      employment_statement: Yup.string().required(
        "Enter your employment statement"
      ),
      date_of_appointment: Yup.string().required("Required"),
      national_insurance_number: Yup.string().required("Required"),
      not_fully_repaid: Yup.string().required("Required"),
      repaying: Yup.string().when("not_fully_repaid", {
        is: "yes",
        then: Yup.string().required("Required"),
      }),
      plan: Yup.string().when("repaying", {
        is: "no",
        then: Yup.string().required("Required"),
      }),
      finish: Yup.string().required("Required"),
    }),
    onSubmit: (data) => {
      setLoading(true);
      console.table(data);
      const url = API_BASE_URL + "final/saveChecklist";
      axios
        .post(url, data)
        .then((response) => {
          if (response.data.status === 200) {
            toast({
              title: "Profile Update",
              description: response.data.message,
              position: "top",
              status: "success",
              isClosable: true,
            });
            setLoading(false);
            formik.resetForm();
          } else {
            toast({
              title: "Profile Update Error",
              description: response.data.message,
              position: "top",
              status: "error",
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          toast({
            title: "error",
            description: error.message,
            position: "top",
            status: "error",
            isClosable: true,
          });
          setLoading(false);
        });
    },
  });

  return (
    <Box className="space-y-5 md:w-1/2  p-2">
      <p className="font-bold text-lg">Employment Details: </p>
      <p className="font-bold text-red-500">
        Please input your start date. If you're not sure of your start date,
        kindly reach out to the recruitment team.
      </p>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
        <CustomInput
          popover="Please input your start date. If you're not sure of your start date, kindly reach out to the recruitment team."
          formLabel="Employment Start Date"
          type="date"
          name="date_of_appointment"
          value={formik.values.date_of_appointment}
          onChange={formik.handleChange}
          isInvalid={
            formik.errors.date_of_appointment &&
            formik.touched.date_of_appointment
          }
        />
        <CustomInput
          formLabel="National Insurance Number"
          type="text"
          name="national_insurance_number"
          value={formik.values.national_insurance_number}
          onChange={formik.handleChange}
          isInvalid={
            formik.errors.national_insurance_number &&
            formik.touched.national_insurance_number
          }
        />
        <CustumSelect
          value={formik.values.employment_statement}
          isInvalid={
            formik.errors.employment_statement &&
            formik.touched.employment_statement
          }
          onChange={formik.handleChange}
          name="employment_statement"
          placeholder="Employment Statement"
          formLabel="Employment Statement"
          options={employement_statement_array}
        />
        <p className="font-bold text-lg col-span-2">Loan</p>
        <div className="">
          {" "}
          <CustumSelect
            value={formik.values.not_fully_repaid}
            isInvalid={
              formik.errors.not_fully_repaid && formik.touched.not_fully_repaid
            }
            onChange={(e) => {
              formik.handleChange(e);
              setNotFullyRepaid(e.target.value === "Yes");
            }}
            name="not_fully_repaid"
            placeholder="Do you have a Student Loan which is not fully repaid?"
            formLabel="Do you have a Student Loan which is not fully repaid?"
            options={yesNo}
          />
        </div>
        {notFullyRepaid && (
          <CustumSelect
            value={formik.values.repaying}
            isInvalid={formik.errors.repaying && formik.touched.repaying}
            onChange={(e) => {
              formik.handleChange(e);
              setRepaying(e.target.value === "Yes");
            }}
            name="repaying"
            placeholder="Are you repaying your Student Loan directly to the Student Loans Company by agreed monthly payments?"
            formLabel="Are you repaying your Student Loan directly to the Student Loans Company by agreed monthly payments?"
            options={yesNo}
          />
        )}
        <div className="col-span-2" hidden={repayingDirect && true}>
          <h2 className="font-bold">Student Loan Plans</h2>
          <p>You will have a Plan 1 Student Loan if:</p>
          <ul className="list-decimal ml-8">
            <li>
              You lived in Scotland or Northern Ireland when you started your
              course, or
            </li>
            <li>
              You lived in England or Wales and started your course before
              September 2012
            </li>
          </ul>
          <p>
            You will have a Plan 2 Student Loan if you lived in England or Wales
            and started your course on or after 1 September 2012.
          </p>
          <CustumSelect
            value={formik.values.plan}
            isInvalid={formik.errors.plan && formik.touched.plan}
            onChange={formik.handleChange}
            name="plan"
            placeholder="What type of Student Loan do you have?"
            formLabel="What type of Student Loan do you have?"
            options={loanPlan}
          />
        </div>

        <CustumSelect
          value={formik.values.finish}
          isInvalid={formik.errors.finish && formik.touched.finish}
          onChange={formik.handleChange}
          name="finish"
          placeholder="Did you finish your studies before the last 6 April?"
          formLabel="Did you finish your studies before the last 6 April?"
          options={yesNo}
        />
        <CustomButton isLoading={loading} type="submit" label="Submit" />
      </form>
    </Box>
  );
}

"use client";

import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { CustomInput } from "../components/Input";

import { CustomButton } from "../components/Buttons";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { API_BASE_URL } from "../components/APIs";
import axios from "axios";
import GeneralLayout from "../layout/GeneralLayout";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email is Invalid email")
        .required("Email is required"),
    }),
    onSubmit: (data) => {
      const url = API_BASE_URL + "/auth/sendPasswordResetLink";
      setLoading(true);
      console.log(data);
      axios
        .post(url, data)
        .then((response) => {
          if (response.data.status === 200) {
            toast({
              title: "Password Reset Message",
              description: response.data.messagge,
              position: "top",
              status: "success",
              isClosable: true,
              size: "lg",
            });
          }
          setLoading(false);
          setSentMessage(true);
        })
        .catch((error) => {
          toast({
            title: "Password Reset Message",
            description: error.messagge,
            position: "top",
            status: "error",
            isClosable: true,
            size: "lg",
          });
          setLoading(false);
        });
    },
  });
  return (
    <GeneralLayout
      description="sss"
      pageContent={
        <>
          <Container
            as={SimpleGrid}
            maxW={"7xl"}
            columns={{ base: 1, md: 2 }}
            spacing={{ base: 10, lg: 32 }}
            py={{ base: "7em", sm: "4em" }}
            height={{ base: "auto", lg: "100vh" }}
            justifyContent={"center"}
          >
            {/* <Stack
            display={{base:'none',md:'flex'}}
              spacing={10}
              justifyContent={"center"}
              textAlign={{ base: "center", lg: "left" }}
            >
              <Heading
                color={"gray.800"}
                // lineHeight={1.1}
                fontSize={{ base: "2xl", sm: "3xl", md: "7xl" }}
              >
                Password Reset
              </Heading>
             <Text color={"gray.500"} fontSize={{ base: "sm", sm: "lg" }}>
                Please input the registwered email to reset your password
              </Text> 
            </Stack> */}
            <Stack
              bg={"white"}
              rounded={"xl"}
              p={{ base: 4, sm: 6, md: 8 }}
              spacing={{ base: 8 }}
              maxW={{ lg: "xl" }}
              justifyContent={"center"}
            >
              {sentMessage ? (
                <>
                  <Stack spacing={2}>
                    <Heading color={'green'}>Password Reset Email Sent! </Heading>
                    <Text
                      color={"gray.500"}
                      fontSize={{ base: "sm", sm: "lg" }}
                    >
                      An Email has been sent to your email address.
                    </Text>
                  </Stack>
                </>
              ) : (
                <>
                  <Stack spacing={2}>
                    <Heading>Password Reset </Heading>
                    <Text
                      color={"gray.500"}
                      fontSize={{ base: "sm", sm: "lg" }}
                    >
                      Please input the registered email address to reset your
                      password
                    </Text>
                  </Stack>
                  <Box as={"form"} onSubmit={formik.handleSubmit}>
                    <Stack spacing={5}>
                      <CustomInput
                        placeholder="Email Address"
                        type="email"
                        isInvalid={formik.errors.email && formik.touched.email}
                        py="7"
                        borderRadius="0"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        formErroMsg={formik.errors.email}
                      />

                      {loading ? (
                        <LoadingSpinner />
                      ) : (
                        <CustomButton
                          type="submit"
                          label="Send Reset Link"
                          width={"full"}
                          bg={"blue.400"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                        />
                      )}

                      <Stack>
                        <Text color="fg.muted" align={"left"}>
                          Didn't have an account?{" "}
                          <Link
                            color={"blue"}
                            textDecoration={"underline"}
                            href="/register"
                          >
                            Sign up
                          </Link>
                        </Text>
                      </Stack>
                    </Stack>
                  </Box>
                </>
              )}
            </Stack>
          </Container>
        </>
      }
    />
  );
}

import { Stack } from "@chakra-ui/react";

import { OnboardingLayout } from "../layout/OnboardingLayout";

import { useEffect } from "react";

import { LoadingSpinner } from "../components/LoadingSpinner";

import DynamicPageTitle from "../components/PageTitle ";

import OnboardingForm from "./CheckList";
import useUserContext from "../components/UserContext";

export default function CheckList() {
  const { userDetails, loading, getUsersInfo } = useUserContext();

  useEffect(() => {
    getUsersInfo();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <DynamicPageTitle pageTitle={"Starter Check List"} />
      <>
        {userDetails && (
          <OnboardingLayout
            pageTitle="Starter Check List!"
            mainContent={
              <div className="p-2 space-y-5">
                <tr className="flex space-x-5 font-bold">
                  <p className="">Full name:</p>
                  <p>
                    {userDetails?.fname} {userDetails?.lname}{" "}
                    {userDetails?.oname}
                  </p>
                  <p className="">Gender:</p>
                  <p>{userDetails?.gender}</p>
                </tr>
                <tr className="flex space-x-5 font-bold">
                  <p className="">Date of Birth:</p>
                  <p>{userDetails?.dob}</p>
                  <p className="">Job Title</p>
                  <p>{userDetails?.job_title}</p>
                </tr>

                <OnboardingForm userDetails={userDetails} />
              </div>
            }
          />
        )}
      </>
    </>
  );
}

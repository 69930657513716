import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import GeneralLayout from "../../layout/GeneralLayout";
import { Hero } from "../../components/Hero";
import { BG_IMAGE_1_URL } from "../../components/APIs";
import { CustomButton } from "../../components/Buttons";
import { SimpleScrollGrid } from "@fullcalendar/core/internal.js";
import { ArrowBackIcon } from "@chakra-ui/icons";
const LiveCare = () => {
  const hover = {
    backgroundColor: "blue.400",
    color: "white",
    transform: "translateX(5px)",
  };
  const buttonTransition =
    '"background-color 0.3s, color 0.3s, transform 0.3s"';
  return (
    <GeneralLayout
    page_type="care"
      pageContent={
        <>
          <Box w={"full"} gap={10}>
            <Hero
              imageURL={BG_IMAGE_1_URL}
              heroTitle="Live-In Care"
              breadcrumb={
                <>
                  <Link href="/healthcare">
                    <ArrowBackIcon fontSize={20} /> Back{" "}
                  </Link>
                </>
              }
            />
            <SimpleGrid
              py={"5em"}
              columns={[1, 3]}
              w={{ base: "100%", md: "80%" }}
              m={"auto"}
              spacing={10}
              px={3}
            >
              <Flex
                position={""}
                bg="blue.200"
                height="450px"
                p={5}
                alignContent={"end"}
                textAlign={"center"}
                rounded={10}
                backgroundImage={
                  "url('/images/adulst-support-over-65years.jpg')"
                }
                backgroundPosition={"center"}
                bgRepeat={"no-repeat"}
                backgroundSize={"cover"}
              >
                <Heading
                  // fontSize={"34pt"}
                  pos={""}
                  alignSelf={"end"}
                  bottom={"1em"}
                  color={"white"}
                  textShadow="2px 2px 5px black"
                >
                  We care and support adults over 65 years
                </Heading>
              </Flex>
              <Flex
                position={""}
                bg="blue.200"
                height="450px"
                p={5}
                rounded={10}
                alignContent={"end"}
                textAlign={"center"}
                backgroundImage={"url('/images/child-support.jpg')"}
                backgroundPosition={"center"}
                bgRepeat={"no-repeat"}
                backgroundSize={"cover"}
              >
                <Heading
                  // fontSize={"34pt"}
                  pos={""}
                  alignSelf={"end"}
                  bottom={"1em"}
                  color={"white"}
                  textShadow="2px 2px 5px black"
                >
                  We care and support children and young adults
                </Heading>
              </Flex>
              <Flex
                position={""}
                bg="blue.200"
                height="450px"
                p={5}
                alignContent={"end"}
                textAlign={"center"}
                rounded={10}
                backgroundImage={
                  "url('/images/adulst-support-under-65years.jpg')"
                }
                backgroundPosition={"center"}
                bgRepeat={"no-repeat"}
                backgroundSize={"cover"}
              >
                <Heading
                  // fontSize={"34pt"}
                  pos={""}
                  alignSelf={"end"}
                  bottom={"1em"}
                  color={"white"}
                  textShadow="2px 2px 5px black"
                >
                  We care and support adults under 65 years
                </Heading>
              </Flex>
            </SimpleGrid>
            <Box py={"7rem"}>
              <Heading
                fontSize={{ base: "3xl", md: "5xl" }}
                lineHeight={"50px"}
                textAlign={"center"}
              >
                Live-in Care
              </Heading>
              <Box
                display="flex"
                w={{ base: "93%", md: "80%" }}
                m={"auto"}
                py={50}
                gap={2}
                flexDirection={{ base: "column", md: "row" }}
              >
                {/* Navigation Tab Link */}

                <Image
                  rounded={20}
                  flex={1}
                  src="/images/24-Hour-Live-In.jpg"
                />

                <Flex
                  flex={1}
                  padding="20px"
                  // Change to your desired background color
                  // boxShadow="md"
                  width={{ base: "100%", md: "500px" }} // Adjust width as needed
                  py={7}
                  px={"1em"}
                  rounded={20}
                  // color="white" // Change to your desired text color
                  fontWeight="bold"
                  gap={10}
                  alignContent={"center"}
                  justifyContent={"center"}
                  flexDir={"column"}
                >
                  <Heading>
                    24-Hour Live-In Care: Professional, Personalized Home
                    Support
                  </Heading>
                  <Text
                    fontSize={{ base: "16px", md: "24px" }}
                    fontWeight={"normal"}
                    pr={"30"}
                  >
                    We have a well tailored 24 hours live-in care for you or
                    your loved ones right in the comfort of your home with
                    highly trained and experienced staff. We can make your home
                    a haven and a place you would always love with our
                    standardised methods and best practices
                  </Text>
                  <Box>
                    <CustomButton
                      label="Find out More"
                      as={"a"}
                      link="/contact"
                      bg={"yellow.300"}
                    />
                  </Box>
                </Flex>
              </Box>
              <Box
                display="flex"
                w={{ base: "93%", md: "80%" }}
                m={"auto"}
                py={50}
                gap={2}
                flexDirection={{ base: "column-reverse", md: "row" }}
              >
                {/* Navigation Tab Link */}

                <Flex
                  flex={1}
                  padding="20px"
                  // Change to your desired background color
                  // boxShadow="md"
                  width={{ base: "100%", md: "500px" }} // Adjust width as needed
                  py={7}
                  px={"1em"}
                  rounded={20}
                  // color="white" // Change to your desired text color
                  fontWeight="bold"
                  gap={10}
                  alignContent={"center"}
                  justifyContent={"center"}
                  flexDir={"column"}
                >
                  <Heading>
                    Comprehensive Person-Centred Care and Support Services
                  </Heading>
                  <Text
                    fontSize={{ base: "16px", md: "24px" }}
                    fontWeight={"normal"}
                    pr={"30"}
                  >
                    Depending on your needs, we can guarantee you of a
                    person-centred service for your personal care,
                    companionship, emotional and physical support, required
                    support in completing your daily tasks satisfactorily which
                    may include showering, getting dressed, taking medication,
                    interacting with the community and helping you to live your
                    desired life.
                  </Text>
                  <Box>
                    <CustomButton
                      label="Get started"
                      as={"a"}
                      link="/contact"
                      bg={"blue.300"}
                    />
                  </Box>
                </Flex>
                <Image
                  rounded={20}
                  flex={1}
                  src="/images/Person-Centred-Care.jpg"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              w={{ base: "93%", md: "80%" }}
              m={"auto"}
              py={50}
              gap={2}
              flexDirection={{ base: "column", md: "row" }}
            >
              {/* Navigation Tab Link */}

              <Image rounded={20} flex={1} src="/images/nurse-standing.jpg" />

              <Flex
                flex={1}
                padding="20px"
                // Change to your desired background color
                // boxShadow="md"
                width={{ base: "100%", md: "500px" }} // Adjust width as needed
                py={7}
                px={"1em"}
                rounded={20}
                // color="white" // Change to your desired text color
                fontWeight="bold"
                gap={10}
                alignContent={"center"}
                justifyContent={"center"}
                flexDir={"column"}
              >
                <Heading>
                  Comprehensive Support for All Ages with Diverse Health Needs
                </Heading>
                <Text
                  fontSize={{ base: "16px", md: "24px" }}
                  fontWeight={"normal"}
                  pr={"30"}
                >
                  We support people of all age group living with eating
                  disorders, mental health conditions, physical disabilities,
                  sensory impairments, substance misuse problems, complex care
                  needs, dementia and other kinds of conditions.
                </Text>
                <Box>
                  <CustomButton
                    label="Make Enquiry now"
                    as={"a"}
                    link="/contact"
                    bg={"blue.400"}
                  />
                </Box>
              </Flex>
            </Box>
            <Flex
              padding="20px"
              // Change to your desired background color
              // boxShadow="md"
              width={{ base: "100%", md: "100%" }} // Adjust width as needed
              py={"7em"}
              px={"1em"}
              bg={"blue.400"}
              mx={"auto"}
              textAlign={"center"}
              // color="white" // Change to your desired text color
              fontWeight="bold"
              alignContent={"center"}
              justifyContent={"center"}
              flexDir={"column"}
              // backgroundImage={"url('/images/contact-us.jpg')"}
              blur={"2"}
              backgroundPosition={"center"}
              bgRepeat={"no-repeat"}
              backgroundSize={"cover"}
              backdropBlur={500}
              _before={{
                opacity: 0.5,
                zIndex: -1,
              }}
              gap={5}
            >
              <Heading
                fontSize={{ base: "3xl", md: "5xl" }}
                lineHeight={"30px"}
                textAlign={"center"}
                // fontWeight={400}
              >
                Our Team Is Always Ready!
              </Heading>
              <Text
                fontSize={{ base: "16px", md: "24px" }}
                fontWeight={"normal"}
                width={["100%", "50%"]}
                mx={"auto"}
              >
                Our team is always ready to assess your needs, conduct a
                comprehensive risk assessment, develop an inclusive
                person-centred care plan, and deliver a safe, high-quality and
                consented care and support plan unique to the individual.
              </Text>
              <Box>
                <CustomButton
                  label="Make Enquiry now"
                  as={"a"}
                  link="/contact"
                  bg={"yellow.400"}
                />
              </Box>
              <Box>
                {/* <CustomButton
                      label="Contact Us"
                      as={"a"}
                      link="/contact"
                      bg={"yellow.400"}
                    /> */}
              </Box>
            </Flex>
          </Box>
        </>
      }
    />
  );
};

export default LiveCare;

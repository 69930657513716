import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import GeneralLayout from "../../layout/GeneralLayout";
import { Hero } from "../../components/Hero";
import { BG_IMAGE_1_URL } from "../../components/APIs";
import { CustomButton } from "../../components/Buttons";

import { ArrowBackIcon } from "@chakra-ui/icons";
import { clientele, profession } from "../../util/data";
const Clientele = () => {
  const hover = {
    backgroundColor: "blue.400",
    color: "white",
    transform: "translateX(5px)",
  };
  const buttonTransition =
    '"background-color 0.3s, color 0.3s, transform 0.3s"';
  return (
    <GeneralLayout
      page_type="recruitment"
      pageContent={
        <>
          <Box w={"full"} gap={10}>
            <Hero
              imageURL={BG_IMAGE_1_URL}
              heroTitle="OUR CLIENTELE"
              breadcrumb={
                <>
                  <Link href="/">
                    <ArrowBackIcon fontSize={20} /> Back{" "}
                  </Link>
                </>
              }
            />

            <Box>
              <Box
                py={"7rem"}
                display="flex"
                w={{ base: "93%", md: "80%" }}
                m={"auto"}
                gap={2}
                flexDirection={{ base: "column", md: "row" }}
              >
                {/* Navigation Tab Link */}

                <Flex
                  flex={1}
                  padding="20px"
                  // Change to your desired background color
                  // boxShadow="md"
                  width={{ base: "100%", md: "500px" }} // Adjust width as needed
                  //   py={7}
                  px={"1em"}
                  rounded={20}
                  // color="white" // Change to your desired text color
                  fontWeight="bold"
                  gap={10}
                  alignContent={"center"}
                  justifyContent={"center"}
                  flexDir={"column"}
                  className="text-center"
                >
                  <h2 className="text-4xl text-balance md:text-7xl">
                    Professionals We Supply:
                  </h2>
                  <Image
                    rounded={20}
                    width={"50%"}
                    margin={"auto"}
                    src="/images/profession.png"
                  />

                  <p className="text-balance text-[24px] font-normal">
                    At Litmus, no time is odd for us, our Support team is
                    readily available to provide Solution whenever the need
                    arises, even at the shortest notice.
                  </p>
                  <div className="flex flex-wrap gap-2 justify-center items-center text-balance md:w-[80%] m-auto  ">
                    {profession &&
                      profession.map((pro, index) => (
                        <p
                          className={
                            `w-auto p-1 text-[30px] font-bold border-white border-4 text-white text-center ` +
                            pro.color
                          }
                        >
                          {pro.client}
                        </p>
                      ))}
                  </div>

                  {/* <Box>
                      <CustomButton
                        label="Find out More"
                        as={"a"}
                        link="/contact"
                        bg={"yellow.300"}
                      />
                    </Box> */}
                </Flex>
              </Box>
              <div className="w-[100%] m-auto justify-center items-center py-20 space-y-10 bg-blue-500">
                <h2 className="text-4xl text-balance md:text-7xl font-bold text-center">
                  OUR CLIENTELE
                </h2>
                <p className="text-[24px] text-center text-balance">
                  Litmus Services Limited serves a diverse clientèle of
                  healthcare facilities, including:
                </p>
                <div className="flex flex-col md:flex-row w-[80%] m-auto justify-center items-center">
                  <div className="flex-none">
                    <img
                      alt=""
                      className="m-auto w-[500px] "
                      src="/images/our-clientele.png"
                    />
                  </div>

                  <div className="flex-1">
                    <div className="flex flex-wrap gap-2 justify-center items-center text-balance md:w-[80%] m-auto  ">
                      {clientele &&
                        clientele.map((client, index) => (
                          <p
                            className={
                              `w-auto p-1 text-[30px] font-bold border-white border-4 text-white text-center ` +
                              client.color
                            }
                          >
                            {client.client}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </Box>

            <Flex
              padding="20px"
              // Change to your desired background color
              // boxShadow="md"
              width={{ base: "100%", md: "100%" }} // Adjust width as needed
              py={"7em"}
              px={"1em"}
              bg={"white"}
              mx={"auto"}
              textAlign={"center"}
              // color="white" // Change to your desired text color
              fontWeight="bold"
              alignContent={"center"}
              justifyContent={"center"}
              flexDir={"column"}
              // backgroundImage={"url('/images/contact-us.jpg')"}
              blur={"2"}
              backgroundPosition={"center"}
              bgRepeat={"no-repeat"}
              backgroundSize={"cover"}
              backdropBlur={500}
              _before={{
                opacity: 0.5,
                zIndex: -1,
              }}
              gap={5}
            >
              <Heading
                fontSize={{ base: "3xl", md: "5xl" }}
                lineHeight={"30px"}
                textAlign={"center"}
                // fontWeight={400}
              >
                Our Team Is Always Ready!
              </Heading>
              <Text
                fontSize={{ base: "16px", md: "24px" }}
                fontWeight={"normal"}
                width={["100%", "50%"]}
                mx={"auto"}
              >
                Our team is always ready to assess your needs, conduct a
                comprehensive risk assessment, develop an inclusive
                person-centred care plan, and deliver a safe, high-quality and
                consented care and support plan unique to the individual.
              </Text>
              <Box>
                <CustomButton
                  label="Make Enquiry now"
                  as={"a"}
                  link="/contact"
                  bg={"yellow.400"}
                />
              </Box>
              <Box>
                {/* <CustomButton
                          label="Contact Us"
                          as={"a"}
                          link="/contact"
                          bg={"yellow.400"}
                        /> */}
              </Box>
            </Flex>
          </Box>
        </>
      }
    />
  );
};

export default Clientele;

import {
  Stack,
  Box,
  Text,
  Heading,
  UnorderedList,
  ListItem,
  Flex,
  SimpleGrid,
  Image,
  useColorModeValue,
  Grid,
  SkeletonCircle,
  Link,
} from "@chakra-ui/react";
import { Hero } from "../../components/Hero";
import { BG_IMAGE_1_URL } from "../../components/APIs";
import { CustomButton } from "../../components/Buttons";
import Header from "../../layout/component/Header";
import GeneralLayout from "../../layout/GeneralLayout";
import { ArrowBackIcon } from "@chakra-ui/icons";
export default function Domiciliary() {
  const hover = {
    backgroundColor: "blue.400",
    color: "white",
    transform: "translateX(5px)",
  };

  const services = [
    {
      title: "Personal care",
      image: "/images/PersonalCare.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Companionship",
      image: "/images/companionship.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Mobilising",
      image: "/images/mobilising.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Day care",
      image: "/images/day-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Overnight care",
      image: "/images/overnight-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Weekend care",
      image: "/images/weekend-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Respite care",
      image: "/images/respite-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Shopping",
      image: "/images/shopping.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Housekeeping",
      image: "/images/housekeeping.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Reablement",
      image: "/images/reablement.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Medication Support",
      image: "/images/medication-support.jpg",
      link: "#", // Provide the image URL
    },
    // Add more services here...
  ];
  const buttonTransition =
    '"background-color 0.3s, color 0.3s, transform 0.3s"';
  return (
    <GeneralLayout
      page_type="care"
      pageContent={
        <>
          <Box w={"100%"} className="landing-bg">
            <Hero
              imageURL={BG_IMAGE_1_URL}
              heroTitle="DOMICILIARY (HOME CARE) SERVICES"
              breadcrumb={
                <>
                  <Link href="/healthcare">
                    <ArrowBackIcon fontSize={20} /> Back{" "}
                  </Link>
                </>
              }
            />
            <SimpleGrid
              py={"5em"}
              columns={[1, 1]}
              w={{ base: "100%", md: "80%" }}
              m={"auto"}
              spacing={10}
              px={3}
            >
              <Flex
                py={5}
                position={""}
                bg="blue.200"
                height={["auto", "450px"]}
                px={[5, "6em"]}
                alignContent={"end"}
                textAlign={"center"}
                rounded={10}
                backgroundImage={"url('/images/people-small-to-old.jpg')"}
                backgroundPosition={"top"}
                bgRepeat={"no-repeat"}
                backgroundSize={"cover"}
              >
                <Heading
                  // fontSize={"34pt"}
                  pos={""}
                  alignSelf={"end"}
                  bottom={["", "1em"]}
                  color={"gray.800"}
                  textShadow="2px 2px 10px white"
                >
                  Quality care and support for people with physical
                  disabilities, eating disorders, mental health conditions,
                  sensory impairments, substance misuse problems, complex care
                  needs and dementia
                </Heading>
              </Flex>
            </SimpleGrid>

            <Box
              display="flex"
              w={{ base: "93%", md: "80%" }}
              m={"auto"}
              py={50}
              gap={2}
              flexDirection={{ base: "column", md: "row-reverse" }}
            >
              {/* Navigation Tab Link */}

              {/* <Image rounded={20} flex={1} src="/images/nurse-standing.jpg" /> */}

              <Flex
                flex={1}
                padding="20px"
                // Change to your desired background color
                // boxShadow="md"
                width={{ base: "100%", md: "500px" }} // Adjust width as needed
                py={7}
                px={"1em"}
                rounded={20}
                // color="white" // Change to your desired text color
                fontWeight="bold"
                gap={10}
                alignContent={"center"}
                justifyContent={"center"}
                flexDir={"column"}
              >
                <Heading>
                  Maximizing Quality of Life at Home for All Care Needs
                </Heading>
                <Text
                  fontSize={{ base: "16px", md: "24px" }}
                  fontWeight={"normal"}
                  pr={"30"}
                >
                  Everyone has varying demands for care/support needs because of
                  short- or long-term disability, old age, illness or medical
                  condition (or for whatever reason). At Litmus Care, we believe
                  that irrespective of these reasons or any other personal
                  circumstance(s), everyone should be able to maximise their
                  quality of life and do so from the safe and comfortable
                  surroundings of their own homes.
                </Text>
                <Box>
                  <CustomButton
                    label="Make Enquiry now"
                    as={"a"}
                    link="/contact"
                    bg={"blue.400"}
                  />
                </Box>
              </Flex>
            </Box>
            <Box
              display="flex"
              w={{ base: "93%", md: "80%" }}
              m={"auto"}
              py={50}
              gap={2}
              flexDirection={{ base: "column", md: "row" }}
            >
              {/* Navigation Tab Link */}

              <Image rounded={20} flex={1} src="/images/nurse-standing.jpg" />

              <Flex
                flex={1}
                padding="20px"
                // Change to your desired background color
                // boxShadow="md"
                width={{ base: "100%", md: "500px" }} // Adjust width as needed
                py={7}
                px={"1em"}
                rounded={20}
                // color="white" // Change to your desired text color
                fontWeight="bold"
                gap={10}
                alignContent={"center"}
                justifyContent={"center"}
                flexDir={"column"}
              >
                <Heading>
                  Personalized Community Care for Independent Living
                </Heading>
                <Text
                  fontSize={{ base: "16px", md: "24px" }}
                  fontWeight={"normal"}
                  pr={"30"}
                >
                  Litmus Care provides people with the level of care and support
                  they require delivered safely and effectively in the
                  community. Our team will support you to live independently as
                  much as possible and provide your care and support tailored to
                  your personal care needs. This may include taking you to GP
                  appointments, supporting you to attend school/classes,
                  attending day care centre or other social events, toileting,
                  showering, dressing, other personal care needs and support
                  completing your daily tasks.
                </Text>
                <Box>
                  <CustomButton
                    label="Make Enquiry now"
                    as={"a"}
                    link="/contact"
                    bg={"blue.400"}
                  />
                </Box>
              </Flex>
            </Box>
            <Stack
              id="s"
              w={{ base: "100%", md: "100%" }}
              m={"auto"}
              py={"20"}
              gap={5}
              bg={useColorModeValue("white", "blue.900")}
            >
              <Box w={["95%", "80%"]} m={"auto"}>
                <Heading
                  // fontWeight={500}
                  fontSize={{ base: "4xl", md: "5xl" }}
                  color={"gray.800"}
                  textAlign={{ base: "center", md: "center" }}
                >
                  Our service
                </Heading>
                <Text
                  w={"60%"}
                  mx={"auto"}
                  mt={5}
                  color={"gray.800"}
                  fontSize={{ base: "16pt", md: "20pt" }}
                  textAlign={{ base: "center", md: "center" }}
                >
                  Our service is available 24 hours a day to provide care and
                  support needs on hourly bases and will cover the following but
                  not limited to:
                </Text>
                <Stack py={5}>
                  {" "}
                  <Grid
                    justifyContent={"center"}
                    templateColumns={{
                      base: "1fr",
                      md: "repeat(4, 1fr)",
                      lg: "repeat(3, 1fr)",
                    }}
                    gap={10}
                  >
                    {services.map((service, index) => (
                      <Box
                        key={index}
                        rounded="xl"
                        border={"2px solid #0074D9"}
                        bg={"blue.50"}
                        justifyContent={"center"}
                        alignContent={"center"}
                        alignItems={"center"}
                        href={service.link}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.2, duration: 0.5 }}
                      >
                        <Image
                          roundedTop={"xl"}
                          style={{
                            width: "100%",
                            height: "700px",
                            objectFit: "cover",
                          }}
                          src={service.image}
                          alt={service.title}
                        />

                        <Stack p={4} spacing={3}>
                          <Text
                            fontSize={"3xl"}
                            fontWeight={400}
                            textAlign={"center"}
                            color={"gray.800"}
                            mb={2}
                          >
                            {service.title}
                          </Text>
                          {/* <Text>Description of Services</Text>
                          <Box>
                            <CustomButton
                              bg={"blue.400"}
                              label={"Learn More"}
                            />
                          </Box> */}
                        </Stack>
                      </Box>
                    ))}
                  </Grid>
                </Stack>
              </Box>
            </Stack>
            <Box
              display="flex"
              w={{ base: "93%", md: "80%" }}
              m={"auto"}
              py={50}
              gap={2}
              flexDirection={{ base: "column", md: "row-reverse" }}
            >
              {/* Navigation Tab Link */}

              {/* <Image rounded={20} flex={1} src="/images/nurse-standing.jpg" /> */}

              <Flex
                flex={1}
                padding="20px"
                // Change to your desired background color
                // boxShadow="md"
                width={{ base: "100%", md: "500px" }} // Adjust width as needed
                py={7}
                px={"1em"}
                rounded={20}
                // color="white" // Change to your desired text color
                fontWeight="bold"
                gap={10}
                alignContent={"center"}
                justifyContent={"center"}
                flexDir={"column"}
              >
                <Heading>
                  Maximizing Quality of Life at Home for All Care Needs
                </Heading>
                <Text
                  fontSize={{ base: "16px", md: "24px" }}
                  fontWeight={"normal"}
                  pr={"30"}
                >
                  Our team is always ready to assess the needs, conduct a
                  comprehensive risk assessment, develop an inclusive
                  person-centred care plan, and deliver a safe, high-quality and
                  consented care and support plan unique to the individual.
                </Text>
                <Text
                  fontSize={{ base: "16px", md: "24px" }}
                  fontWeight={"normal"}
                  pr={"30"}
                >
                  Our home care services will allow you and your family to enjoy
                  the peace of mind needed to keep our service user safe in the
                  home.
                </Text>
                <Box>
                  <CustomButton
                    label="Make Enquiry now"
                    as={"a"}
                    link="/contact"
                    bg={"blue.400"}
                  />
                </Box>
              </Flex>
            </Box>
          </Box>
        </>
      }
    />
  );
}

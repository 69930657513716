import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

const AgencyAgreementDoc = ({
  userDetails,
  agree,
  date,
  employeeCommenceDate,
}) => {
  const containerStyle = {
    width: "600px",
    margin: "0 auto",
    // paddingTop: '.2em',
    paddingBottom: "1em",
    paddingLeft: "3.5em",
    paddingRight: "3.5em",
    fontFamily: "Arial, sans-serif",
    "page-break-after": "always",
    boxShadow: "lg",
  };

  return (
    <div style={containerStyle}>
      <div className="font-normal text-[11px] flex flex-col space-y-3  text-justify ">
        {/* <Image w={"80%"} mx={"auto"} src={"/images/letter_head.png"} /> */}
        <div className="flex items-center">
          <img alt="" src="/litmus-logo.png" height={10} />
          <div className="flex flex-col">
            <p className="text-4xl text-red-500 font-bold">Litmus Care</p>
            <p>
              Sherbourne House, Humber Avenue, Coventry, CV1 2AQ
              jobs@litmusservices.co.uk | www.litmusservices.co.uk 02080797305
            </p>
          </div>
        </div>
        <br />
        <p
          style={{
            paddingBottom: "10px",
            fontSize: "9pt",
            textAlign: "right",
          }}
        >
          {date}
        </p>
        <p>
          {userDetails && (
            <>
              {userDetails?.fname} {userDetails?.oname} {userDetails?.lname},
              <br />
              {userDetails?.house_number} {userDetails?.address_line1}, <br />
              {userDetails?.town_city}, {userDetails?.county}, <br />
              {userDetails?.post_code}.
            </>
          )}
        </p>

        <h1 className="text-center font-bold uppercase">
          Contract of Employment
        </h1>
        <p>
          This statement dated {date} meets the requirements of the
          Employment Rights Act 1996. It is the Contract of Employment of the
          person named below and supersedes any previous such statements or
          contracts.
        </p>
        <section className="font-bold   ">
          <table className="table-auto text-left">
            <tr className="">
              <td className="">Name of Employee:</td>
              <td>
                {userDetails?.fname} {userDetails?.oname} {userDetails?.lname}
              </td>
            </tr>
            <tr>
              <td>Name of Employer: </td>
              <td>Litmus Services Ltd</td>
            </tr>
            <tr>
              <td>Address of Employer: </td>
              <td>
                Sherbourne House, Humber Avenue, Coventry, West Midlands CV1 2AQ
              </td>
            </tr>
            <tr>
              <td>Start date: </td>
              <td>{date}</td>
            </tr>
            <tr>
              <td>Job title: </td>
              <td>{userDetails?.job_title}</td>
            </tr>
          </table>
        </section>
        <p>
          The Organisation may from time to time require you to undertake
          additional or other duties as necessary to meet the needs of the
          Organisation on a short term basis e.g. holiday or sickness cover.
        </p>
        <section className="space-y-2">
          <h2 className="font-bold">Probationary period</h2>
          <p>
            New employees join the Organisation on a six-month probationary
            period.
          </p>
          <p>
            During and/or at the end of your probationary period you may be
            asked to attend employment reviews to discuss your overall work
            performance and conduct. This may include consideration of your
            absence record, competency, timekeeping, attitude, and interactions
            with colleagues and clients. If the Organisation is satisfied with
            your overall work performance and conduct, including gaining the
            Care Certificate, your continuing employment will be confirmed.
          </p>
          <p>
            If the Organisation is not satisfied with your overall performance
            and conduct, or you have failed to gain the required
            certification(s), your employment will be terminated with the
            required notice.
          </p>
          <p>
            The Organisation reserves the right in borderline cases to extend
            the probationary period, in the hope that during any further
            periods, your work performance and conduct will reach a level that
            is satisfactory to the Organisation. After such further period, a
            subsequent employment review will be held and a decision made. Your
            continuing employment will then either be confirmed, terminated with
            the required notice or the probationary period could be extended.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Right to Work in the UK</h2>
          <p>
            You have the responsibility maintain the condition of the right to
            work and to notify the Organisation as soon as you become aware of
            any changes to your personal circumstances that may affect your
            right to work in the UK. The Organisation reserves the right to
            conduct periodic checks and/or request documentation in relation to
            your right to work in the UK as appropriate, at any time.
          </p>
        </section>
        <section>
          <h2 className="font-bold">
            Disclosure and Barring Service (DBS) Check
          </h2>
          <p>
            Your employment is conditional upon a satisfactory DBS check and you
            are expected to cooperate with us in obtaining this. Failure to do
            so will result in the termination of your employment.
          </p>
          <p>
            The Organisation will fund the cost of your DBS check. If your
            employment is terminated within your probationary period by either
            party, for any reason other than redundancy, you agree that the
            Organisation has the right to deduct the cost of this check from
            your pay or any other monies owed to you.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Place of Work</h2>
          <p>
            Your normal place of work is at the homes of our service users. You
            will also be expected to travel to various clients’ premises or
            other addresses as necessary from time to time.
          </p>
          <p>You will not be required to work outside the UK.</p>
        </section>
        <section>
          <h2 className="font-bold">Pay Arrangements</h2>

          <p>
            Salary payment is made monthly directly into your bank/building
            society on or around the last day of the month.
          </p>
          <p>
            If a mistake is made in the payment of any monies due, the
            Organisation expects to be notified immediately. The error will
            normally be corrected at the next available opportunity.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Contractual payments and benefits</h2>
          <p>You are entitled to the following payments and benefits:</p>
        </section>
        <section>
          <h2 className="font-bold">Overtime</h2>
          <p>
            All hours worked in excess of your normal hours in a month and are
            paid at single time.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Mileage allowance</h2>
          <p>
            You will be required to use your own vehicle to travel to client’s
            houses. You will be paid for travel time from client-to-client
            houses.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Hours of work</h2>
          <p>
            Your normal hours of work will be 40 hours per week between Mondays
            to Sundays. These normal hours of work may be varied to meet the
            operational requirements of the Organisation. <br />
            You are required as part of your normal duties to be on call in
            order to provide out of hours cover to deal with emergencies. If you
            are called in you will be entitled to time off in lieu. You may be
            required to work a reasonable amount of overtime hours as directed
            by the Organisation.
            <br />
            You may also be required to work in the capacity of care
            cordinator/admin staff to cover staff absence. You will be entitled
            to time off in lieu / overtime for these hours.
          </p>
        </section>
        <section>
          <h2>Out of hours responsibilities</h2>
          <p>
            As part of your normal duties you are required to undertake specific
            duties relevant to your position including staff meetings and
            mandatory training, which may be outside of your normal working
            hours as follows:
          </p>
          <div className="space-y-3">
            <section className="">
              <h2 className="underline">Work-based training:</h2>
              <p>
                It is essential that you are kept up to date with the ongoing
                changes and skills required in respect of your position.
                Therefore it is a condition of employment that time is spent
                undertaking work-based training that ensures compliance with
                Regulations.
              </p>
            </section>
            <section className="">
              <h2 className="underline">Staff meetings:</h2>
              <p>
                There are regular staff meetings which you are required to
                attend. They are normally held at a time which is convenient for
                most employees to attend.
              </p>
            </section>
          </div>
        </section>
        <section>
          <h2 className="font-bold">Training:</h2>
          <p>
            The Organisation will provide you with all the necessary training
            required by your core duties and will meet the costs involved.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Holiday Entitlement</h2>
          <p>
            The holiday year runs from 1st January to 31st December to 31
            December. Full-time employees are entitled to 5.6 weeks' holiday a
            year, inclusive of any bank/public holidays that they may be
            permitted to take, calculated at the rate of 1/52nd of the annual
            entitlement for each completed week of service in the current
            holiday year.
          </p>
          <p>
            In terms of taking holiday during your first year of service only,
            your entitlement to take holidays will accrue each month at the rate
            of 1/12th of the annual entitlement.
          </p>
          <p>
            Therefore, on your first day of employment you will accrue the right
            to take 1/12th of the annual entitlement and you will continue to
            accrue the right to take 1/12th following each month of service
            (calculated from your start date) during that first year. Where the
            current accrual includes a fraction of a day other than a half-day,
            the fraction will be treated as a half-day if it is less than a
            half-day and as a whole day if it is more than a half-day. At all
            times, booking a holiday will be governed by the Organisation’s
            holiday request rules. However, if your employment terminates at any
            time, including during the first year, your entitlement will be
            calculated at an accrual rate of 1/52nd for each completed week of
            service in a holiday year.
          </p>
          <p>There is no additional entitlement to bank/public holidays.</p>
          <p>
            You will be required to work on any bank/public holiday that falls
            on your rostered working days.
          </p>
          <p>
            If you are required to work on a bank/public holiday you will be
            paid at your normal rate and will take the time off in lieu at
            another time. If your work that day is less than a full day, then
            the time off in lieu will be rounded up to the nearest half day.
          </p>
          <p>Part-time employees are entitled to pro-rata holidays.</p>
          <p>
            A more detailed explanation is contained in the Employee Handbook.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Holiday pay</h2>
          <p>
            Payment for holidays will be at your normal basic rate under your
            terms and conditions of employment for your normal hours of work.
          </p>
          <p>
            On termination of employment, holiday entitlement will be calculated
            at the rate of 1/52nd of the annual entitlement for each completed
            week of service in the current holiday year. If you have taken less
            than this entitlement the surplus holiday pay will be added to your
            final pay. If you have taken more than this entitlement the excess
            holiday pay will be deducted from your pay.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Sickness Absence</h2>
          <p>
            The Organisation is required to pay Statutory Sick Pay for certain
            periods of sickness absence. Payment may be made to eligible
            employees for periods of absence of four days or more. There is a
            maximum period of 28 weeks payment in one period of incapacity for
            work.
          </p>
          <p>
            A more detailed explanation is contained in the Employee Handbook.
          </p>
          <p>
            If you receive or are awarded compensation or damages because of
            your illness or injuries, then any payments that we may have made to
            you because of the absence must be repaid to us up to an amount not
            exceeding the amount of the compensation or damages received.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Other Leave</h2>
          <p>
            Subject to eligibility, as set out in the Employee Handbook, the
            Organisation provides the following statutory leave:
          </p>
          <ul className="list-disc ml-8">
            <li>maternity leave</li>
            <li>paternity leave</li>
            <li>parental bereavement leave</li>
            <li>parental leave</li>
            <li>shared parental leave</li>
            <li>adoption leave</li>
          </ul>
          <p>
            Further details can be found in the Organisation’s Employee
            Handbook.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Disciplinary Procedure and Rules</h2>
          <p>
            Should your conduct or performance fall below the standards required
            then disciplinary action may be taken. This procedure is designed to
            help and encourage employees to achieve and maintain the
            Organisation's standards of conduct and performance and should be
            looked upon as a corrective process.
          </p>
          <p>
            A more detailed explanation of the procedure and rules is contained
            in the Employee Handbook.
          </p>
        </section>
        <section>
          <h2>Disciplinary appeal procedure</h2>
          <p>
            You have the right to appeal at any stage in the disciplinary
            procedure if you are dissatisfied either with a disciplinary
            decision made against you or the level of penalty imposed. You
            should do this in writing to a Director not previously involved in
            the disciplinary decision within five days of receiving your
            confirmation of discipline letter.
          </p>
          <p>
            A more detailed explanation of the procedure is contained in the
            Employee Handbook.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Grievance Procedure</h2>
          <p>
            If you have any grievance relating to your employment, you should
            raise it with your Line Manager (or an alternative Manager as
            appropriate) in the first instance. If you want the grievance to be
            dealt with formally, you must raise it in writing.
          </p>
          <p>
            A more detailed explanation of the formal procedure is contained in
            the Employee Handbook.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Pension Scheme</h2>
          <p>
            Under the current pensions legislation you will be enrolled into an
            ‘auto enrolment’ pension scheme if you are eligible. If you are not
            automatically enrolled into the scheme you may still be entitled to
            join. Further details will be provided separately.
          </p>
        </section>
        <section>
          <h2>Collective agreements</h2>
          <p>
            There are no collective agreements directly affecting your terms and
            conditions of employment.
          </p>
          <p className="font-bold">Notice periods</p>
          <p className="underline">
            Notice period to be given by the employee to the employer.
          </p>
          <ul className="list-disc ml-8 ">
            <li>Less than one month’s service – nil.</li>
            <li>
              One month’s service to the satisfactory completion of your
              probationary period – one week.
            </li>
            <li>
              From the satisfactory completion of your probationary period but
              less than nine years – two months.
            </li>
            <li>
              Nine years’ service or more – one week for each complete year of
              service up to a maximum of 12 weeks.
            </li>
          </ul>
        </section>
        <section>
          <h2 className="font-bold">General</h2>
          <p>
            If you leave without giving and working your full notice, any
            additional cost in covering your duties during the notice period not
            worked will be deducted from any termination pay due to you.
          </p>
          <p>
            The Organisation may serve immediate notice on you to require you to
            take some or all of any outstanding holiday entitlement that you may
            have during your notice period. This clause amends the obligations
            to provide notice of taking holiday under regulation 15(5) of the
            Working Time Regulations.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Deduction from pay</h2>
          <p>
            If the Organisation deems it reasonable that during or on
            termination of your employment, and after any investigation which
            the Organisation deems appropriate, you should reimburse the
            Organisation for any financial sum and you agree that the
            Organisation has the right to deduct this sum from your pay or any
            other monies owed to you. You agree to any such deduction/s pursuant
            to part II of the Employment Rights Act 1996. Examples of deductions
            which may be made by the Organisation include, but are not limited
            to the following:
          </p>
          <ul className="list-disc ml-8">
            <li>The amount of any overpayment of pay, commission, or bonus.</li>
            <li>
              Any holiday pay relating to leave you have taken in excess of that
              which you have accrued at the point of termination.
            </li>
            <li>Outstanding loan or pay advance repayments.</li>
            <li>Over-claimed or disallowed expenses.</li>
            <li>
              Any cash floats not repaid by you at the end of your employment.
            </li>
            <li>Any other sums owed to the Organisation by you.</li>
            <li>
              Costs incurred through Organisation Credit Card and/or Fuel Card
              misuse.
            </li>
            <li>
              Cost of any unauthorised personal phone calls/data usage or
              personal fuel.
            </li>
            <li>
              Cost of replacement or repair of Organisation property not
              returned, lost, stolen, or damaged due to your lack of care during
              or after your employment.
            </li>
            <li>
              Other costs reasonably incurred in connection with equipment not
              returned, returned damaged or unusable during or after your
              employment (e.g. replacement of locks where keys are not
              returned).
            </li>
            <li>
              Any insurance excess payable by the Organisation as a result of
              damage caused by your negligence or lack of care.
            </li>
            <li>
              An amount equal to our reasonable loss or the extra cost of
              covering your duties should you fail to work your full contractual
              notice. This applies when you leave employment early without
              agreement.
            </li>
            <li>
              Any fines, penalties or losses sustained that is the result of
              your carelessness, negligence, deliberate vandalism, dishonesty or
              a breach of Organisation rules.
            </li>
            <li>
              Any monies paid or payable by the Organisation to any third party
              due to any conduct undertaken by you for which we may be deemed
              vicariously liable.
            </li>
            <li>
              Any damages, expenses or other monies reasonably payable by us to
              a third party for your act or omission.
            </li>
            <li>
              Any deductions elsewhere under this contract in relation to which
              the reserved right to deduct applies.
            </li>
            <li>
              Any deductions authorised by any separate agreement into which the
            </li>
            <li>
              Organisation has entered with you (for example for training
              costs).
            </li>
          </ul>
        </section>
        <section>
          <h2 className="font-bold">Deduction Procedure</h2>
          <p>
            {" "}
            We will investigate where the Organisation deems this appropriate,
            in respect of any deduction we make. Any deduction is by way of
            compensation, based on a genuine assessment of monies due. Where we
            deem it relevant and appropriate, we will set out a pre-estimate of
            losses and this will be provided to you. If the Organisation
            reasonably decides to use any insurance policy to claim for the
            losses incurred, the amount of any deduction will be no more than
            the insurance excess.
          </p>
          <p>
            {" "}
            We do not use the deduction provision to impose penalties. However,
            we may separately follow the disciplinary process around the
            relevant circumstances the Organisation deems it appropriate.
          </p>
          <p>
            A repayment plan may be agreed and where insufficient remuneration
            is due to cover the cost, civil court action may be taken to recover
            any outstanding debt.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Training costs agreement</h2>
          <p>
            The Organisation may require you to sign a ‘Training costs
            agreement’ form prior to you undertaking any training. This
            Agreement will authorise the Organisation to make a deduction for
            the cost of the training (on a pro-rata basis) if you fail to
            complete any relevant course and/or sit and pass all or any tests or
            exams associated with it, or if your employment ends within the
            agreed term (stipulated in the agreement) of completing any training
            course for any reason except redundancy.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Pay in lieu</h2>
          <p>
            Whether following a resignation or a dismissal, it is agreed that
            the Organisation may terminate your employment with immediate effect
            on notification that a payment in lieu of notice is to be made to
            you. You shall not be entitled to any benefit other than pay in
            respect of any period for which payment in lieu is to be made.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Garden leave</h2>
          <p>
            The Organisation reserves the right to require you to remain away
            from your place of employment for all or part of your notice period,
            with or without work, whether you or the Organisation gives notice.
            You must accept that whilst still employed by the Organisation on
            notice either at home or on Organisation premises you must not work
            for any other company, firm, person or business.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Lay off/short time working</h2>
          <p>
            The Organisation reserves the right to lay off employees or to
            introduce short-time working should this be required by a downturn
            in work or other needs of the business. Where short-time working is
            introduced, pay will be reduced in proportion with the reduction in
            working hours.
          </p>
          <p>
            A more detailed explanation of the procedure is shown in the
            Employee Handbook.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Confidentiality</h2>
          <p>
            The Organisation operates a strict policy on confidential
            information regarding its employees, its service users and their
            families. The nature of our business means that our standards and
            measurement of success depends on information remaining
            confidential.
          </p>
          <p>These information includes, but is not limited to:</p>
          <ul className="list-disc ml-8">
            <li>service users’/relatives’ records,</li>
            <li>
              personal data relating to prospective, current or past employees,
            </li>
            <li>
              marketing policies or pricing information in relation to the
              Organisation,
            </li>
            <li>medical records (internal and external),</li>
            <li>accounts information,</li>
            <li>medical or technical information.</li>
          </ul>
          <p>
            You will not (except in the proper performance of your duties)
            either during your employment or at any time after its termination
            for whatever reason without the prior written consent of the
            Organisation or as required by law, either directly or indirectly:
          </p>
          <ul>
            <li>disclose any confidential information to any person,</li>{" "}
            <li>
              use any confidential information for your own benefit or for the
              benefit of any other person, company or other undertaking,
            </li>{" "}
            <li>
              knowingly permit or enable any person (including yourself) to
              acquire or to make use of any such confidential information for
              any purpose in a manner which may cause loss or damage to the
              Organisation.
            </li>
          </ul>
          <p>
            For the avoidance of doubt, this includes all service user
            information relating to their activities during their time with us,
            including their medical, financial or family records.
          </p>
          <p>
            With respect to any confidential information (including personal
            data) disclosed to or accessed by you, you must ensure that you
            notify the Organisation of any unauthorised or unlawful processing
            or any accidental loss, destruction, damage, alteration or
            disclosure of personal or confidential data as soon as you become
            aware and keep the Organisation informed of any related
            developments.
          </p>
          <p>
            The Organisation will regard any breach of confidentiality or of its
            confidentiality policy (see separate document) as a disciplinary
            offence and serious breaches will lead to dismissal without notice
            for gross misconduct.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Conflict of interest</h2>
          <p>
            During your employment you will be expected to devote the whole of
            your working time and attention to Organisation business and to use
            your best endeavours to promote the general interests of the
            Organisation.
          </p>
          <p>
            If required to do so you must provide details of any relationships
            with any Organisation customers or suppliers and comply with any
            reasonable instructions given to you by the Organisation on such
            relationships.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Other employment</h2>
          <p>
            You should make the Organisation aware if you wish to take up
            additional employment. You must provide details of the proposed
            employment and consideration will be given to:
          </p>
          <ul>
            <li>Working hours,</li>
            <li>Competition, reputation and credibility, and</li>
            <li>Health, safety and welfare.</li>
          </ul>
          <p>
            If the proposed alternative employment conflicts with the
            performance of your duties to the Organisation, this may create a
            conflict of interest.
          </p>
          <p>
            In order to work more than an average of 48 hours in a week, you
            must sign an individual waiver form.
          </p>
        </section>
        <section>
          <h2 className="font-bold">Private work</h2>
          <p>
            You are not permitted to take on private work for any customer or
            client or prospective customer or client of the Organisation during
            your employment, either during or outside working hours, except
            where prior written authorisation has been given. You must not
            provide quotations for private work, and if approached for such, you
            must inform management immediately. All queries for work must be
            directed to management and you should not discuss pricing at any
            point unless authorised to do so.
          </p>
        </section>

        <section>
          <h2 className="font-bold">
            Other terms and conditions of employment
          </h2>
          <p>
            Any agreed amendments that materially alter the terms and conditions
            contained in your contract will be notified to you in writing and
            shall take precedence over the terms in this statement.
          </p>
        </section>
        <section className="flex space-x-1">
          <p>Issued by </p>
          <p className="font-bold">Iseoluwa Owoseni</p> <p>(for Employer)</p>
        </section>
        <section className="flex items-center space-x-5">
          <p>Signed :</p>
          <img
            src="/images/iseoluwa_sign.png"
            alt="employer signature"
            height={10}
          />
        </section>
        <section className="flex items-center space-x-5">
          <p>Date : {date}</p>
        </section>
        <section className="flex items-center space-x-1">
          <p> Received by</p>
          <p className="font-bold">
            {userDetails?.fname} {userDetails?.oname} {userDetails?.lname}
          </p>
          <p> (Employee)</p>
        </section>
        <p>
          I have read, understood and am willing to abide by the terms and
          conditions laid down in the Employee Handbook and accept that they
          form an integral part of this Contract of Employment.
        </p>
        <p className="mt-5">
          Signed: {userDetails?.fname} {userDetails?.oname} {userDetails?.lname}
        </p>
        <p className="mt-5">Date: {date}</p>
      </div>

      {agree && (
        <Box
          my={2}
          alignSelf={"center"}
          bg={"green.100"}
          borderRadius={"lg"}
          p={3}
        >
          <Text color={"green.500"} fontWeight={800} fontSize={"9pt"}>
            I acknowledge receipt of the terms and conditions of my employment
            and accept the terms and conditions atteched. Accepted by:{" "}
            {userDetails?.fname + " " + userDetails?.lname}
          </Text>
        </Box>
      )}
    </div>
  );
};

export default AgencyAgreementDoc;

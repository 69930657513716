import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormErrorMessage,
  Heading,
  Image,
  Stack,
  Text,

  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import jsPDF from "jspdf";
import Cookies from "js-cookie";

import useUserContext from "../components/UserContext";
import { OnboardingLayout } from "../layout/OnboardingLayout";
import { API_BASE_URL } from "../components/APIs";


const OptoutAgreement = () => {
  const { userDetails } = useUserContext();
  const uid = Cookies.get("uid");
  const [isLoading, setLoading] = useState(false);
  const contractTemplateRef = useRef(null);
  const toast = useToast();

  const optout = userDetails?.optout_agreement;
  const optout_agreement = optout ? JSON.parse(optout) : null;
  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      compress: true,
      floatPrecision: "smart",
    });

    doc.html(contractTemplateRef.current, {
      async callback(doc) {
        doc.save(
          `${userDetails?.fname}_${userDetails?.lname}_OPT_OUT_AGREEMENT_Litmus_Services_Ltd.pdf`
        );
        window.location.reload();
      },
      margin: [30, 0, 30, 0],
      autoPaging: "text",
    });
  };

  const formik = useFormik({
    initialValues: {
      user_id: "",
      signature: "",
      date_signed: "",
    },
    validationSchema: Yup.object({
      agree: Yup.boolean()
        .oneOf([true], "You must accept the terms and conditions")
        .required("You must accept the terms and conditions"),
    }),
    onSubmit: (data) => {
      setLoading(true);
      const optout = JSON.stringify(data);
      alert(optout);
      const url = `${API_BASE_URL}/final/saveOptOut`;
      axios
        .post(url, { optout_agreement: optout, user_id: uid })
        .then((response) => {
          const { status, message } = response.data;
          toast({
            title: status === 200 ? "Profile Update" : "Profile Update Error",
            description: message,
            position: "top",
            status: status === 200 ? "success" : "error",
            isClosable: true,
          });
          if (status === 200) {
            handleGeneratePdf();
          }
          setLoading(false);
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: error.message,
            position: "top",
            status: "error",
            isClosable: true,
          });
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (userDetails) {
      formik.setValues({
        agree: optout_agreement?.agree === "1",
        signature: `${userDetails?.fname} ${userDetails?.lname}`,
        user_id: Cookies.get("uid"),
        date_signed: formattedDate,
      });
    }
    setLoading(false);
  }, [userDetails]);

  const currentDate =
    userDetails?.optout_agreement != null
      ? new Date(optout_agreement?.date_signed)
      : new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  return (
    <OnboardingLayout
      pageTitle="Contract Agreement"
      mainContent={
        <Stack as="form" onSubmit={formik.handleSubmit} mb={5}>
          <Stack
            width="600px"
            style={{
              size: "7in 9.25in",
              margin: "27mm 16mm 27mm 16mm;",
            }}
            mx="auto"
            boxShadow="lg"
            my={5}
            bg="white"
            ref={contractTemplateRef}
          >
            {/* <Flex gap={0} flexDir={"column"} mx={"auto"} alignItems={"center"}>
              <Text style={{ fontSize: "9px" }} lineHeight={0} pb={1}>
                <Image h={"70px"} src={"/images/litmus-logo.png"} />
                ...partnership that works
              </Text>
              <Text textAlign={"center"} fontSize={"8pt"}>
                Sherbourne House, Humber Avenue, Coventry, CVI 2AQ
              </Text>
              <Text textAlign={"center"} fontSize={"8pt"} fontWeight={600}>
                {" "}
                www.litmusservices.co.uk | jobs@litmusservices.co.uk |
                02080797305
              </Text>
            </Flex> */}
            <form className="bg-white w-[600px] mx-auto py-[20px] px-5 text-base space-y-5 ">
              <div className="text-center space-y-5 ">
                <Heading className="text-center text-[2rem]">
                  {userDetails?.fname} {userDetails?.oname} {userDetails?.lname}
                </Heading>
                <p>
                  {userDetails?.house_number === 0 && userDetails?.house_number}{" "}
                  {userDetails?.address_line1}, {userDetails?.town_city}{" "}
                  {userDetails?.county}, {userDetails?.country}.{" "}
                  {userDetails?.post_code}
                </p>
              </div>
              <p>
                The Manager
                <br />
                Litmus Service Limited
              </p>
              <Heading className="text-center my-2 ">
                48 HOUR PER WEEK OPT OUT AGREEMENT
              </Heading>
              <div>
                <p>
                  I agree that I may work for more than an average of 48 hours a
                  week.
                </p>
                <p>
                  If I change my mind, I will give my employer at least 2
                  months’ notice in writing to end this agreement.
                </p>
              </div>
              <p>
                Employee’s Name: {userDetails?.fname} {userDetails?.oname}{" "}
                {userDetails?.lname}
                <br />
                Signed: {formik.values.signature}
                <br />
                Dated: {formattedDate}
                <br />
              </p>
            </form>
          </Stack>

          <Stack width="600px" mx="auto" spacing={5}>
            {optout_agreement?.agree === true ? (
              <Stack justifyContent="center" spacing={3}>
                <Text px={5} textAlign="center">
                  You have agreed to the terms and conditions with{" "}
                  {userDetails?.job_title}
                </Text>
                <Button
                  isLoading={isLoading}
                  onClick={handleGeneratePdf}
                  width="300px"
                  mx="auto"
                  loadingText="Generating Document"
                  variant="outline"
                  spinnerPlacement="start"
                  colorScheme="blue"
                >
                  Download Opt-out Agreement again
                </Button>
                {/* <Button
                  width="300px"
                  mx="auto"
                  type="submit"
                  as="a"
                  href="/dashboard"
                  loadingText="Generating Document"
                  variant="link"
                  spinnerPlacement="start"
                  colorScheme="green"
                >
                  Proceed to Staff Dashboard
                </Button> */}
              </Stack>
            ) : (
              <>
                <input
                  type="hidden"
                  name="user_id"
                  value={formik.values.user_id}
                  onChange={formik.handleChange}
                />
                <input
                  type="hidden"
                  name="date_signed"
                  value={formik.values.date_signed}
                  onChange={formik.handleChange}
                />
                <input
                  type="hidden"
                  name="signature"
                  value={formik.values.signature}
                  onChange={formik.handleChange}
                />
                <Checkbox
                  size="lg"
                  name="agree"
                  isChecked={formik.values.agree}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.agree && formik.touched.agree}
                >
                  I agree to the terms and conditions by signing this "48 HOUR
                  PER WEEK OPT OUT AGREEMENT".
                </Checkbox>
                <FormErrorMessage>{formik.errors.agree}</FormErrorMessage>
                <Box>
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    loadingText="Generating Document"
                    variant="outline"
                    spinnerPlacement="start"
                    colorScheme="blue"
                  >
                    Accept & Download Agreement Letter
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      }
    />
  );
};

export default OptoutAgreement;

"use client";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  ListItem,
  List,
  Link,
  ListIcon,
  Slide,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Logo } from "../../components/Logo";
import { AvatarMenu } from "./AvatarMenu";
import { useAuthentication } from "../../auth/useAuthentication";

export default function PortaNavBar({ navTextColor, hasHero = false }) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const isAuthenticated = useAuthentication();
  return (
    <Box>
      <Flex
        // bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"90px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={0}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Logo height={"50px"} />

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          {isAuthenticated ? (
            <AvatarMenu />
          ) : (
            <>
              <Button
                color={navTextColor}
                as={"a"}
                fontSize={"sm"}
                fontWeight={700}
                variant={"link"}
                href={"/login"}
              >
                Login
              </Button>
              <Button
                as={"a"}
                display={{ base: "none", md: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={700}
                color={"white"}
                bg={"blue.400"}
                href={"/register"}
                _hover={{
                  bg: "blue.50",
                  color: "blue.400",
                }}
              >
                Register
              </Button>
            </>
          )}
        </Stack>
      </Flex>

      {/* <Collapse >
       
      </Collapse> */}
    </Box>
  );
}

import classNames from 'classnames';
import React from 'react';

interface YesNoProps {
    label: string; // The question or label for the radio buttons
    name: string; // The name attribute for the radio buttons (used for form handling)
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Function for handling radio button changes
    value?: string; // Optional value to represent the selected option (defaults to "no")
    className?: string; // Optional prop for adding custom classes
    isInvalid?: any; // Optional prop to indicate an invalid state (affects styling)


}

const YesNoInput: React.FC<YesNoProps> = ({ label, name, onChange, value, className, isInvalid }) => {
    const inputClasses = classNames(
        'w-full text-base text-black py-2 px-2 rounded border border-black', // Base styles
        isInvalid ? 'border-red-500' : '', // Error state styling
        className || '' // Apply any custom classes
    );

    return (
        <div className="flex items-center justify-between mb-2 md:w-4/6">
            {label && <label htmlFor={name} className="text-black text-base font-medium block mb-1">{label}</label>}
            <div className='items-center space-x-3'>
                <label htmlFor={`${name}-yes`}>Yes:</label>
                <input
                    type="radio"
                    id={`${name}-yes`}
                    name={name}
                    value="yes"
                    onChange={onChange}
                    className=" rounded-md border-gray-300 focus:ring-primary-500 focus:ring-1"
                />
                <label htmlFor={`${name}-no`}>No:</label>
                <input
                    type="radio"
                    id={`${name}-no`}
                    name={name}
                    value="no"
                    onChange={onChange}
                    className=" rounded-md border-gray-300 focus:ring-primary-500 focus:ring-1"
                />
            </div>
        </div>
    );
};

export default YesNoInput;

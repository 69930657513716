import {
  Box,
  Flex,
  Grid,
  // HStack,
  Heading,
  Highlight,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { Hero } from "../../components/Hero";
import { CustomButton } from "../../components/Buttons";
import AnimatedBox from "../../components/AnimatedBox";
import QQualityTag from "../../components/QQualityTag";

import GeneralLayout from "../../layout/GeneralLayout";
import { FaArrowRight } from "react-icons/fa";

const LitmusRecruitment = () => {
  // const hover = {
  //   // backgroundColor: 'blue.400',
  //   color: "blue.400",
  //   transform: "translateX(5px)",
  // };
  // const buttonTransition =
  //   '"background-color 0.3s, color 0.3s, transform 0.3s"';
  const activities = [
    {
      title: "Healthcare",
      description:
        "We provide registered nurses, healthcare assistants, other professionals and support staff with specialized training to meet the unique demands of the healthcare industry.",
    },
    {
      title: "Construction",
      description:
        "We are ready to supply certified tradespeople such as electricians, plumbers, and carpenters with the necessary qualifications and experience.",
    },
    {
      title: "Logistics and Supply Chain",
      description:
        "We are licensed to supply reliable personnel for roles in warehousing and supply chain management.",
    },
  ];
  const apart = [
    {
      title: "Compliance and Quality Assurance: GLAA Licensed",
      description:
        "Our GLAA licence demonstrates our commitment to preventing labor exploitation and ensuring fair treatment of all workers.",
    },
    {
      title: "Our Vetting Process",
      description:
        "We conduct thorough background checks and skill assessments to ensure the highest quality of candidates.",
    },
    {
      title: "Ongoing Training and Development",
      description:
        "We provide continuous professional development programs to keep our workforce up-to-date with industry standards and best practices.",
    },
    {
      title: "Client-Centred Solution",
      description:
        "We tailor our services to meet the unique needs of each client, providing bespoke workforce solutions that drive efficiency and performance.",
    },
  ];
  const services = [
    {
      title: "Personal care",
      image: "/images/PersonalCare.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Care and support for adults over 65 yrs",
      image: "/images/support_over65yrs.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Caring and support for adults under 65 yrs",
      image: "/images/care-for-young-under-65years.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Care and support for children and young people",
      image: "/images/care-for-young.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Dementia care",
      image: "/images/dementia-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Eating disorders Care",
      image: "/images/eating-disorder.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Mental health conditions",
      image: "/images/mental-disorder.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Physical disabilities care and support",
      image: "/images/disabilities.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Sensory impairments care and support",
      image: "/images/sensory.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Substance misuse problems support",
      image: "/images/substance-misuse.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Multiple Sclerosis care",
      image: "/images/Multiple-Sclerosis.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Complex care and support",
      image: "/images/complex-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Rehabilitation and Reablement",
      image: "/images/reablement.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "End of life care",
      image: "/images/eol-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Companionship",
      image: "/images/companionship.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Respite care",
      image: "/images/respite-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Errand services",
      image: "/images/errand-services.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Housekeeping",
      image: "/images/housekeeping.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Medication Support",
      image: "/images/medication-support.jpg",
      link: "#", // Provide the image URL
    },
    // Add more services here...
  ];
  // const IamLookinFor = [
  //   {
  //     title: "Live-in Care",
  //     image: "/images/litmus-bg.jpg",
  //     description: "", // Provide the image URL
  //   },
  //   {
  //     title: "Domiciliary",
  //     image: "/images/litmus-bg.jpg",
  //     description: "", // Provide the image URL
  //   },
  // Add more services here...
  // ];
  return (
    <GeneralLayout
      hasHero={true}
      page_type="recruitment"
      pageContent={
        <>
          <Box w={"full"}>
            <Hero
              videURL="/video/litmus.mp4"
              heroTitle="Litmus Recruitment"
              heroDescription="Litmus Services Limited is a premier multi-sector supplier of labor and skilled workers, dedicated to delivering exceptional service across diverse industries. With a strong commitment to quality and compliance, we hold the GLAA (Gangmasters and Labour Abuse Authority) Licence, underscoring our adherence to the highest standards of ethical labor practices.
"
              heroActionButton={
                <div className="w-full">
                  {/* <div className="flex flex-col  md:flex-row md:space-x-5 gap-3 items-center content-center justify-center">
                    <a
                      className="text-base bg-blue-700   text-white flex-none px-5 rounded-lg py-4 md:w-3/12 w-full 
                  transition ease-in-out delay-200 hover:-translate-y-1 hover:scale-110  duration-200"
                      href="/register"
                    >
                      Get Started
                    </a>
                  </div> */}

                  <div className="flex justify-center items-center absolute left-[50%]  md:bottom-10 bottom-5">
                    <svg
                      className="w-10 h-10 text-white-600 animate-bounce"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              }
            />
            <div
              style={{
                backgroundImage: "url('/images/career.jpg')",
                backgroundRepeat: "no-repeat",
                // backgroundPosition: "fixed",
                backgroundSize: "center",
                objectFit: "cover",
                backgroundPositionY: "fixed",
              }}
              className="hover:transition-all"
            >
              <div
                className="flex flex-col h-[auto] justify-center items-center gap-24 md:py-24 md:px-32 px-5 py-24 w-full backdrop-blur-md "
                style={{
                  background: "rgba(255, 255, 255,0.7)",
                }}
              >
                <h2 className="text-4xl sm:text-6xl md:text-7xl text-balance  font-bold tracking-tight text-black ">
                  Key Capabilities
                </h2>

                {/* Navigation Tab Link */}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-5  lg:max-w-5xl xl:max-w-7xl w-full">
                  <div
                    className="  bg-gradient-to-tr from-blue-800 to-violet-900 hover:to-blue-900 hover:from-violet-800  rounded-lg px-6 py-8 ring-1 space-y-5 ring-blue-900/5 shadow-xl hover:bg-violet-800 
                transition ease-in-out delay-150  hover:-translate-y-2 hover:scale-101 duration-300  "
                  >
                    <div>
                      <span class="inline-flex items-center justify-center p-2 bg-blue-500 rounded-md shadow-lg ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="#e8eaed"
                        >
                          <path d="M420-280h120v-100h100v-120H540v-100H420v100H320v120h100v100ZM160-120v-480l320-240 320 240v480H160Zm80-80h480v-360L480-740 240-560v360Zm240-270Z" />
                        </svg>
                      </span>
                    </div>
                    <h3 class="text-white mt-5 text-3xl font-bold tracking-tight ">
                      Skilled Worker Supply
                    </h3>
                    <p class="text-blue-100  mt-2 text-[12pt]">
                      We provide highly skilled and qualified professionals
                      tailored to meet specific client requirements across
                      various sectors, including healthcare, construction,
                      logistics, and administrative services.
                    </p>
                    <span className="inline-flex items-center justify-center   rounded-md shadow-lg">
                      <a
                        href="/recruitment/contact"
                        className="text-base  bg-blue-400  text-black flex-none px-5 rounded-lg py-2 hover:bg-blue-500
                      
                      "
                      >
                        Get Started
                      </a>
                    </span>
                  </div>
                  <div
                    className="bg-white bg-gradient-to-tr from-blue-700 to-blue-900 hover:to-blue-700 hover:from-blue-900 rounded-lg px-6 py-8 ring-1 space-y-5 ring-blue-900/5 shadow-xl
                transition ease-in-out delay-150  hover:-translate-y-2 hover:scale-101 duration-300  "
                  >
                    <div>
                      <span class="inline-flex items-center justify-center p-2 bg-blue-500 rounded-md shadow-lg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="#e8eaed"
                        >
                          <path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z" />
                        </svg>
                      </span>
                    </div>
                    <h3 class="text-white mt-5 text-3xl font-bold tracking-tight">
                      Temporary and Permanent Staffing:
                    </h3>
                    <p class="text-blue-100  mt-2 text-[12pt]">
                      Our flexible staffing solutions cater for both short-term
                      project needs and long-term employment, ensuring seamless
                      workforce integration and productivity.
                    </p>
                    <span className="inline-flex items-center justify-center   rounded-md shadow-lg">
                      <a
                        href="/recruitment/contact"
                        className="text-base  bg-blue-500  text-black flex-none px-5 rounded-lg py-2 "
                      >
                        Get Started
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Box className="flex flex-col  md:max-w-5xl mx-auto px-5">
              <h2 className="text-4xl sm:text-6xl md:text-7xl text-balance   font-bold tracking-tight text-black text-center py-11">
                Sector Expertise:
              </h2>
              <div className="grid md:grid-cols-3 gap-5 ">
                {activities.map((act, index) => (
                  <div
                    className=" bg-violet-900 hover:to-blue-700 hover:from-blue-900 rounded-lg px-6 py-8 ring-1 space-y-5 ring-blue-900/5 shadow-xl
transition ease-in-out delay-150  hover:-translate-y-2 hover:scale-101 duration-300  "
                  >
                    <h3 class="text-white  text-3xl font-bold tracking-tight">
                      {act.title}
                    </h3>
                    <p class="text-blue-100  mt-2 text-base text-justify">
                      {act.description}
                    </p>
                  </div>
                ))}
              </div>
            </Box>
            <Box className="flex flex-col  md:max-w-5xl mx-auto px-5">
              <h2 className="text-4xl sm:text-6xl md:text-7xl text-balance   font-bold tracking-tight text-black text-center py-11">
                What sets us apart
              </h2>
              <div className="grid md:grid-cols-2 gap-5 ">
                {apart.map((act, index) => (
                  <div
                    className=" bg-blue-900 hover:to-blue-700 hover:from-blue-900 rounded-lg px-6 py-8 ring-1 space-y-5 ring-blue-900/5 shadow-xl
transition ease-in-out delay-150  hover:-translate-y-2 hover:scale-101 duration-300  "
                  >
                    <h3 class="text-white  text-3xl font-bold tracking-tight">
                      {act.title}
                    </h3>
                    <p class="text-blue-100  mt-2 text-base text-justify">
                      {act.description}
                    </p>
                  </div>
                ))}
              </div>
              {/* <FeedbackForm /> */}
              <QQualityTag />
            </Box>
          </Box>
        </>
      }
    />
  );
};

export default LitmusRecruitment;

"use client";

import { Stack, Box, useToast, Spinner, Image } from "@chakra-ui/react";

import { OnboardingLayout } from "../layout/OnboardingLayout";
import {
  CustomInput,
  CustomTextarea,
  CustumSelect,
  CustumSelectPosition,
} from "../components/Input";
import { CustomButton } from "../components/Buttons";
import FileInput from "../components/FIleInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CustomSearchableSelect,
  ListCountries,
} from "../components/SelectCountry";

import { useEffect, useState } from "react";
import { API_BASE_URL } from "../components/APIs";
import axios from "axios";
import { LoadingSpinner } from "../components/LoadingSpinner";

import DynamicPageTitle from "../components/PageTitle ";

import { useNavigate } from "react-router-dom";
import useUserContext from "../components/UserContext";
import Cookies from "js-cookie";
import YesNoInput from "../components/CustomCheckbox";

export default function NightWorker() {
  const navigate = useNavigate();

  const { userDetails, loading, getUsersInfo } = useUserContext();

  const [isLoading, setLoading] = useState();
  useEffect(() => {
    getUsersInfo();
  }, []);
  const toast = useToast();

  const uid = Cookies.get("uid");
  const formik = useFormik({
    initialValues: {
      address: "",
      national_insurance_number: "",
      hasDiabetes: "",
      hasCirculatoryDisorders: "",
      hasStomachDisorders: "",
      hasDifficultySleeping: "",
      hasChronicChestDisorders: "",
      hasAnyMedicalCondition: "",
      hasAnyOtherHealthFactors: "",
      user_id: uid,
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Please Enter your address"),
      national_insurance_number: Yup.string().required(
        "Please enter your national insurance number"
      ),
      // hasDiabetes: Yup.string().required("Please "),
      // hasCirculatoryDisorders: Yup.string().required("Please select an option"),
      // hasStomachDisorders: Yup.string().required("Please select an option"),
      // hasDifficultySleeping: Yup.string().required("Please select an option"),
      // hasChronicChestDisorders: Yup.string().required(
      //   "Please select an option"
      // ),
      // hasAnyMedicalCondition: Yup.string().required("Please select an option"),
      // hasAnyOtherHealthFactors: Yup.string().required(
      //   "Please select an option"
      // ),
    }),
    onSubmit: (data) => {
      const night_worker = JSON.stringify(data);
      setLoading(true);
      const url = API_BASE_URL + "final/saveNightWorker";
      axios
        .post(url, { night_worker: night_worker, user_id: uid })
        .then((response) => {
          if (response.data.status === 200) {
            toast({
              title: "Profile Update",
              description: response.data.message,
              position: "top",
              status: "success",
              isClosable: true,
            });
            setLoading(false);
            navigate("/onboarding/optout-agreement");
          } else {
            toast({
              title: "Profile Update Error",
              description: response.data.message,
              position: "top",
              status: "error",
              isClosable: true,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          toast({
            title: "error",
            description: error.message,
            position: "top",
            status: "error",
            isClosable: true,
          });
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    const nightwork = userDetails?.night_worker;
    const night_worker = nightwork ? JSON.parse(nightwork) : null;
    if (nightwork) {
      formik.setValues({
        lname: userDetails.lname,
        fname: userDetails.fname,
        oname: userDetails.oname || "",
        dob: userDetails.dob || "",
        gender: userDetails.gender,
        job_title: userDetails.job_title,
        national_insurance_number: night_worker.national_insurance_number,
        address: night_worker.address,
        hasDiabetes: night_worker.hasDiabetes,
        hasCirculatoryDisorders: night_worker.hasCirculatoryDisorders,
        hasStomachDisorders: night_worker.hasStomachDisorders,
        hasDifficultySleeping: night_worker.hasDifficultySleeping,
        hasChronicChestDisorders: night_worker.hasChronicChestDisorders,
        hasAnyMedicalCondition: night_worker.hasAnyMedicalCondition,
        hasAnyOtherHealthFactors: night_worker.hasAnyOtherHealthFactors,
      });
    } else {
      formik.setValues({
        lname: userDetails?.lname,
        fname: userDetails?.fname,
        oname: userDetails?.oname || "",
        dob: userDetails?.dob || "",
        gender: userDetails?.gender,
        job_title: userDetails?.job_title,
      });
    }
  }, [userDetails]);
  if (loading) {
    return <LoadingSpinner />;
  }
  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "non-binary", label: "Non-binary" },
    { value: "genderqueer", label: "Genderqueer" },
    { value: "transgender", label: "Transgender" },
    { value: "genderfluid", label: "Genderfluid" },
    { value: "agender", label: "Agender" },
    { value: "bigender", label: "Bigender" },
  ];

  return (
    <>
      <DynamicPageTitle pageTitle={"Personal Info"} />
      {userDetails && (
        <OnboardingLayout
          pageTitle="Night Worker Shift"
          mainContent={
            <Stack
              gap={4}
              direction={{ base: "column", md: "column", sm: "column" }}
              as={"form"}
              onSubmit={formik.handleSubmit}
            >
              <input
                value={formik.values.user_id}
                onChange={formik.handleChange}
                type="hidden"
                name="user_id"
              />
              {/* Names */}

              <div className="w-full grid md:grid-cols-2 gap-2">
                <CustomInput
                  disabled
                  formLabel="Surname / Last Name"
                  type="text"
                  name="lname"
                  value={formik.values.lname}
                />
                <CustomInput
                  disabled
                  formLabel="First Name"
                  type="text"
                  name="fname"
                  value={formik.values.fname}
                />
                <CustomInput
                  disabled
                  formLabel="Other names"
                  type="text"
                  name="oname"
                  value={formik.values.oname}
                />
                <CustumSelect
                  formLabel={"Gender"}
                  options={gender}
                  name={"gender"}
                  disabled
                  value={formik.values.gender}
                  placeholder="Select Gender"
                  formformLabel="Sex"
                />
                <CustomInput
                  disabled
                  formLabel="Date of Birth"
                  type="date"
                  name="dob"
                  value={formik.values.dob}
                />
                <CustomTextarea
                  isInvalid={formik.errors.address && formik.touched.address}
                  formErroMsg={formik.errors.address}
                  name="address"
                  formLabel="Permanent Address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />

                <CustomInput
                  disabled
                  formLabel="Job Title"
                  type="text"
                  name="job_title"
                  value={formik.values.job_title}
                  isInvalid={
                    formik.errors.job_title && formik.touched.job_title
                  }
                />
                <CustomInput
                  formLabel="National Insurance Number"
                  type="text"
                  name="national_insurance_number"
                  value={formik.values.national_insurance_number}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.errors.national_insurance_number &&
                    formik.touched.national_insurance_number
                  }
                />
              </div>
              <p className="font-bold">Health conditions</p>
              <YesNoInput
                label="Do you have diabetes?"
                name="hasDiabetes"
                value={formik.values.hasDiabetes}
                onChange={formik.handleChange}
                required
              />
              <YesNoInput
                label="Heart or circulatory disorders?"
                name="hasCirculatoryDisorders"
                value={formik.values.hasCirculatoryDisorders}
                onChange={formik.handleChange}
                required
              />
              <YesNoInput
                label="Stomach or intestinal disorders?"
                name="hasStomachDisorders"
                value={formik.values.hasStomachDisorders}
                onChange={formik.handleChange}
                required
              />
              <YesNoInput
                label="Any condition which causes difficulties sleeping?"
                name="hasDifficultySleeping"
                value={formik.values.hasDifficultySleeping}
                onChange={formik.handleChange}
                required
              />
              <YesNoInput
                label="Chronic chest disorders (especially if night-time symptoms are troublesome)?"
                name="hasChronicChestDisorders"
                value={formik.values.hasChronicChestDisorders}
                onChange={formik.handleChange}
                required
              />
              <YesNoInput
                label="Any medical condition requiring medication to a strict timetable?"
                name="hasAnyMedicalCondition"
                value={formik.values.hasAnyMedicalCondition}
                onChange={formik.handleChange}
                required
              />
              <YesNoInput
                label="Any other health factors that might affect fitness at work?"
                name="hasAnyOtherHealthFactors"
                value={formik.values.hasAnyOtherHealthFactors}
                onChange={formik.handleChange}
                required
              />
              <Box py={5} gap={5} display={"flex"} flexDir={"row"}>
                {isLoading ? (
                  <Spinner
                    thickness="3px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                  />
                ) : (
                  <CustomButton
                    type="submit"
                    bg={"blue.400"}
                    colorScheme="blue"
                    label="Save"
                  />
                )}
                <Box justifySelf={"center"}>
                  <CustomButton
                    as={"a"}
                    type="submit"
                    bg={"blue.400"}
                    colorScheme="blue"
                    label="Next"
                    link="/onboarding/optout-agreement"
                  />
                </Box>
              </Box>
            </Stack>
          }
        />
      )}
    </>
  );
}

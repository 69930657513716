import React, { useCallback, useEffect, useRef, useState } from "react";

import useUserContext from "../components/UserContext";
import AgencyAgreementDoc from "./AgreementDoc";
import { OnboardingLayout } from "../layout/OnboardingLayout";
import CommunityCareAgreementDoc from "./CommunityCareDoc";
import axios from "axios";
import jsPDF from "jspdf";

import {
  Box,
  Button,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";

import Contract from "../offer/AgencyOfferLetter";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../components/APIs";



const AdminContractAgreement = () => {
  const [userDetails, setUserDetails] = useState();
  const { user_id } = useParams();

  const getUsersInfo = useCallback(async () => {
    try {
      setLoading(true);
      const url = API_BASE_URL + "application/getUserDetails";

      const response = await axios.post(url, { user_id: user_id });

      setUserDetails(response?.data);
      // setUser(response?.data);

      localStorage.setItem("userInfo", JSON.stringify(response?.data));
      Cookies.set("getUserInfo", true);
      setLoading(false);
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  }, []); // Empty dependency array ensures the function reference doesn't change

  useEffect(() => {
    getUsersInfo();
  }, [getUsersInfo]);

  const currentDate =
  userDetails?.check_list == "1"
    ? new Date(userDetails?.date_of_appointment)
    : new Date();
const formattedDate = currentDate.toLocaleDateString("en-US", {
  month: "long", // Full month name
  day: "numeric", // Day of the month
  year: "numeric", // Full year
});

  const category = userDetails?.job_category;
  const sector = userDetails?.sector;


  const name = userDetails?.fname + " " + userDetails?.lname;
  const [isLoading, setLoading] = useState();
  const contractTemplateRef = React.useRef(null);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleGeneratePdf = () => {
    // setGenerating(true);
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      compress: true,
      floatPrecision: "smart", // or "smart", default is 16
    });
    // Adding the fonts.
    // doc.setFont('Verdana', 'normal');

    doc.html(contractTemplateRef.current, {
      async callback(doc) {
        doc.save(name + " Contract Agreement Litmus Services Ltd");
        window.location.reload();
      },
      margin: [30, 0, 30, 0],
      autoPaging: "text",
    });
  };

  const agree = userDetails?.agree === 1 ? true : false;
  const signature = userDetails?.fname + " " + userDetails?.lname;

  const cand_name =
    userDetails?.fname?.trim() +
    " " +
    userDetails?.lname.trim() +
    " " +
    userDetails?.oname.trim();
  const [employeeadd, setEmployeeAdd] = useState("");
  useEffect(() => {
    // Move the employee address calculation inside the useEffect
    if (userDetails?.applicant_type === "International") {
      const address = JSON.parse(userDetails?.international_address);
      setEmployeeAdd(
        address.house_number +
          ", " +
          address.address_line1 +
          ", " +
          address.town_city +
          ", " +
          address.state +
          ", " +
          address.applicant_location
      );
    } else {
      setEmployeeAdd(
        userDetails?.address_line1 +
          " " +
          userDetails?.town_city +
          " " +
          userDetails?.county +
          ", " +
          userDetails?.post_code
      );
    }
  }, [userDetails]);
  // handleGeneratePdf();
  const offerCreatedOn = userDetails?.date_created;
  return (
    <>
      <OnboardingLayout
        noAuth={false}
        pageTitle="Contract Agreement"
        mainContent={
          <Box width={"100%"} pos={"relative"}>
            <Stack as={"form"} mb={5}>
              <Stack
                width={"600px"}
                mx={"auto"}
                boxShadow={"lg"}
                my={5}
                bg={"white"}
                ref={contractTemplateRef}
              >
                {category == 1 && (
                  <>
                    <AgencyAgreementDoc
                      date={formattedDate}
                      userDetails={userDetails}
                      agree={agree}
                    />
                  </>
                )}
                {category == 2 && (
                  <>
                    <Contract
                      position={userDetails.job_title}
                      fname={userDetails?.fname}
                      employeeCommenceDate={formattedDate}
                      employeeName={cand_name}
                      employeeAddress={employeeadd}
                      agree={agree}
                      signature={signature}
                      offerCreatedOn={offerCreatedOn}
                      category={category}
                      sector={sector}
                      acknowledged={agree}
                    />
                  </>
                )}
              </Stack>
            </Stack>
            {/* {application_type == "" || (null && "<CareOffer />")} */}

            <Button
              pos={"fixed"}
              top={"50%"}
              bg={"white"}
              left={"45%"}
              isLoading={isLoading}
              onClick={handleGeneratePdf}
              // width={"300px"}
              loadingText="Generating Document"
              variant="outline"
              spinnerPlacement="start"
              colorScheme="blue"
            >
              Download Staff Agreement
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default AdminContractAgreement;

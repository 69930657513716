import React, { useCallback, useEffect, useRef, useState } from "react";

import useUserContext from "../components/UserContext";

import { OnboardingLayout } from "../layout/OnboardingLayout";

import axios from "axios";
import jsPDF from "jspdf";

import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";

import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../components/APIs";

const AdminOptOutAgreement = () => {
  const [userDetails, setUserDetails] = useState();
  const { user_id } = useParams();

  const getUsersInfo = useCallback(async () => {
    try {
      setLoading(true);
      const url = API_BASE_URL + "application/getUserDetails";

      const response = await axios.post(url, { user_id: user_id });

      setUserDetails(response?.data);
      // setUser(response?.data);

      localStorage.setItem("userInfo", JSON.stringify(response?.data));
      Cookies.set("getUserInfo", true);
      setLoading(false);
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  }, []); // Empty dependency array ensures the function reference doesn't change

  useEffect(() => {
    getUsersInfo();
  }, [getUsersInfo]);

  // {"agree":true,"signature":"Test Data","user_id":"294","date_signed":"June 5, 2024"}
  const [isLoading, setLoading] = useState(false);
  const contractTemplateRef = useRef(null);
  const toast = useToast();
  const optout = userDetails?.optout_agreement;
  const optout_agreement = optout ? JSON.parse(optout) : null;
  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      compress: true,
      floatPrecision: "smart",
    });

    doc.html(contractTemplateRef.current, {
      async callback(doc) {
        doc.save(
          `${userDetails?.fname}_${userDetails?.lname}_OPT_OUT_AGREEMENT_Litmus_Services_Ltd.pdf`
        );
        window.location.reload();
      },
      margin: [30, 0, 30, 0],
      autoPaging: "text",
    });
  };

  const currentDate = new Date(optout_agreement?.date_signed);
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  return (
    <>
      <OnboardingLayout
        noAuth={false}
        pageTitle="Contract Agreement"
        mainContent={
          <Box width={"100%"} pos={"relative"}>
            <Stack mb={5}>
              <Stack
                width="600px"
                style={{
                  size: "7in 9.25in",
                  margin: "27mm 16mm 27mm 16mm;",
                }}
                mx="auto"
                boxShadow="lg"
                my={5}
                bg="white"
                ref={contractTemplateRef}
              >
                {/* <Flex gap={0} flexDir={"column"} mx={"auto"} alignItems={"center"}>
              <Text style={{ fontSize: "9px" }} lineHeight={0} pb={1}>
                <Image h={"70px"} src={"/images/litmus-logo.png"} />
                ...partnership that works
              </Text>
              <Text textAlign={"center"} fontSize={"8pt"}>
                Sherbourne House, Humber Avenue, Coventry, CVI 2AQ
              </Text>
              <Text textAlign={"center"} fontSize={"8pt"} fontWeight={600}>
                {" "}
                www.litmusservices.co.uk | jobs@litmusservices.co.uk |
                02080797305
              </Text>
            </Flex> */}
                <form className="bg-white w-[600px] mx-auto py-[20px] px-5 text-base space-y-5 ">
                  <div className="text-center space-y-5 ">
                    <Heading className="text-center text-[2rem]">
                      {userDetails?.fname} {userDetails?.oname}{" "}
                      {userDetails?.lname}
                    </Heading>
                    <p>
                      {userDetails?.house_number === 0 &&
                        userDetails?.house_number}{" "}
                      {userDetails?.address_line1}, {userDetails?.town_city}{" "}
                      {userDetails?.county}, {userDetails?.country}.{" "}
                      {userDetails?.post_code}
                    </p>
                  </div>
                  <p>
                    The Manager
                    <br />
                    Litmus Service Limited
                  </p>
                  <Heading className="text-center my-2 ">
                    48 HOUR PER WEEK OPT OUT AGREEMENT
                  </Heading>
                  <div>
                    <p>
                      I agree that I may work for more than an average of 48
                      hours a week.
                    </p>
                    <p>
                      If I change my mind, I will give my employer at least 2
                      months’ notice in writing to end this agreement.
                    </p>
                  </div>
                  <p>
                    Employee’s Name: {userDetails?.fname} {userDetails?.oname}{" "}
                    {userDetails?.lname}
                    <br />
                    Signed: {userDetails?.fname} {userDetails?.oname}
                    <br />
                    Dated: {formattedDate}
                    <br />
                  </p>
                </form>
              </Stack>

              <Stack width="600px" mx="auto" spacing={5}>
                <Stack justifyContent="center" spacing={3}>
                  <Text px={5} textAlign="center">
                    You have agreed to the terms and conditions with{" "}
                    {userDetails?.job_title}
                  </Text>
                  <Button
                    isLoading={isLoading}
                    onClick={handleGeneratePdf}
                    width="auto"
                    mx="auto"
                    loadingText="Generating Document"
                    variant="outline"
                    spinnerPlacement="start"
                    colorScheme="blue"
                  >
                    Download {userDetails?.fname} {userDetails?.oname} Opt-out
                    Agreement
                  </Button>
                  {/* <Button
                  width="300px"
                  mx="auto"
                  type="submit"
                  as="a"
                  href="/dashboard"
                  loadingText="Generating Document"
                  variant="link"
                  spinnerPlacement="start"
                  colorScheme="green"
                >
                  Proceed to Staff Dashboard
                </Button> */}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default AdminOptOutAgreement;

import React from "react";

const FileViewer = ({ fileUrl }) => {
  // Function to determine file type based on URL extension
  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif", "bmp"].includes(extension))
      return "image";
    if (["pdf"].includes(extension)) return "pdf";
    if (["doc", "docx"].includes(extension)) return "doc";
    return "unknown";
  };

  const fileType = getFileType(fileUrl);

  return (
    <div>
      {fileType === "image" ? (
        <>
          {/* <img
            src={fileUrl}
            alt="file"
            style={{ maxWidth: "70%", height: "auto" }}
          /> */}
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            View {fileUrl} {fileType.toUpperCase()}
          </a>
        </>
      ) : fileType === "pdf" || fileType === "doc" ? (
        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          View {fileUrl} {fileType.toUpperCase()}
        </a>
      ) : (
        <p>Unsupported file type</p>
      )}
    </div>
  );
};

export default FileViewer;

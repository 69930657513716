"use client";

import {
  Box,
  Center,
  chakra,
  Container,
  Divider,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { ReactNode } from "react";
import { Logo } from "../../components/Logo";
import { CustomButton } from "../../components/Buttons";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blue.400", "whiteAlpha.100")}
      rounded={"full"}
      w={10}
      h={10}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blue.300", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const headingColor = {
    fontSize: { base: "", md: "7xl", sm: "" },
    textColor: useColorModeValue("gary.500", "gray.900"),
  };

  return (
    <Box
      width={"100%"}
      bg={useColorModeValue("gary.200", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Box
        display="flex"
        w={{ base: "93%", md: "90%" }}
        m={"auto"}
        py={50}
        gap={2}
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* Navigation Tab Link */}
        <Box
          flex={1}
          // backgroundImage={"url('/images/nurse-standing.jpg')"}
          // backgroundPosition={"center"}
          // bgRepeat={"no-repeat"}
          // backgroundSize={"cover"}
          rounded={20}
          py={7}
          justifyContent={"center"}
          alignContent={"cenetr"}
        >
          <Stack
            justify={{ base: "center", md: "space-between" }}
            align={{ base: "center", md: "center" }}
          >
            <Logo alignSelf={"center"} justifySelf={"center"} height={"auto"} />
          </Stack>
        </Box>

        <Box
          flex="6"
          padding="20px"
          // Change to your desired background color
          // boxShadow="md"
          width={{ base: "100%", md: "500px" }} // Adjust width as needed
          py={7}
          rounded={20}
          backgroundColor="blue.500"
          color="white" // Change to your desired text color
          fontWeight="bold"
          gap={10}
          justifyContent={"center"}
          alignContent={"cenetr"}
          display={"flex"}
          flexDirection={{ base: "column", md: "row", sm: "column" }}
        >
          {/* <Stack flex={2}>
            <Heading style={headingColor} >Site Links</Heading>
          </Stack>
          <Stack flex={2}>
            <Heading >Site Links</Heading>
          </Stack> */}
          <Stack flex={1.5} textAlign={{ base: "center", md: "left" }}>
            <Heading headingColor>Our Social Media</Heading>
            <Stack
              flex={1}
              flexDir={"row"}
              justifyContent={{ base: "center", md: "left", sm: "center" }}
              alignContent={{ base: "center", md: "left", sm: "center" }}
              align={{ sm: "center", base: "center", md: "left" }}
            >
              <SocialButton
                label={"Twitter"}
                href={"https://x.com/LitmusServices?s=08"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height={20}
                  fill="#e8eaed"
                  viewBox="0 0 512 512"
                >
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </SocialButton>
              <SocialButton
                label={"FaceBook"}
                href={"https://www.facebook.com/profile.php?id=100086248770985"}
              >
                <FaFacebook />
              </SocialButton>
              <SocialButton
                label={"Instagram"}
                href={"https://www.instagram.com/litmusservices/"}
              >
                <FaInstagram />
              </SocialButton>
            </Stack>
            <Box width={"full"} m={"auto"} bg={"transparent"} mt={5}>
              <Box
                w={{ base: "100%", md: "100%" }}
                display={"flex"}
                m={"auto"}
                flexDir={{
                  base: "column-reverse",
                  md: "row",
                  sm: "column-reverse",
                }}
                // gap={5}
              >
                <Stack
                  dir=""
                  flex={1}
                  color={"white"}
              
                  className="text-sm text-center md:text-left"
                >
                  <div>
                    Powered by{" "}
                    <Link href="https://waterwaysdigital.com/">
                      Waterways Digital Ltd.
                    </Link>
                  </div>
                </Stack>
                <Stack
                  spacing={5}
                  color={"white"}
                  flex={1}
                  flexDir={"row"}
                  justifyContent={{
                    base: "center",
                    md: "flex-end",
                    sm: "center",
                  }}
                  alignContent={{
                    base: "center",
                    md: "flex-end",
                    sm: "center",
                  }}
                  align={{ sm: "center", base: "center" }}
                >
                  <Text>
                    ©2024 Litmus Services Limited. All rights reserved
                  </Text>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
